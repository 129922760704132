import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const RewardOptions = styled.div`
margin: 20px 0;

.cell-heading {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.trip-description {
  text-align: center;
}

.cell-image {
  display: inline-block;
  padding: 15px 10px;
  max-width: 500px;
  margin: auto;
}


.top-artist-grid {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.cell {
  padding: 10px 10px 30px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.beauty-bonus {
  margin: auto;
  padding: 30px;
  background-color: #000;
  max-width: 160px;
  height: auto;
  text-align: center;
  border-radius: 20px;
}

.beauty-bonus p {
  color: #fff;
}

@media screen and (min-width: ${props => props.theme.responsive.medium}) {
  .top-artist-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cell {
    padding: 10px;
    border-bottom: none;
    border-right: 1px solid #000;
  }
}
`

const RewardDescription = styled.p`
  text-align: center;
  font-size: 16px;
  margin: 10px 0;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 20px 0;
  }
`

const CustomList = styled.ul`
  line-height: 2em;
  margin: 0 auto 32px;
`

const CustomListItem = styled.li`
  &::before {
    content: '/';
    color: #000;
    margin-right: 5px;
  }
`

const PointSystem = styled.div`
  text-align: center;
  text-transform: uppercase;

  h4 {
    font-weight: 600;
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin-top: 32px;
  }
`

const RewardsLevelFour = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        sort: { fields: [description], order: [ASC] },
        filter: { description: { regex: "/.*rewards-tier-three.*/" } }
      ) {
        edges {
          node {
            id
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const rewardImages = data.allContentfulAsset.edges

  return (
    <>
      <h2 style={{ textTransform: 'uppercase', fontWeight: '600' }} className="headline--medium center-align">Rewards Level IV</h2>
      <RewardDescription>
        Trip for Complimentary Guest + Airfare for Artist and Guest + Additional
        Night Stay for Artist and Guest
      </RewardDescription>
      <RewardOptions>
        <div className="top-artist-grid">
          <div className="cell" key="key1">
            <h4 className="cell-heading">Option I</h4>
            <img className="cell-image" src={rewardImages[0].node.file.url} alt={rewardImages[0].node.title} />
            <p className="trip-description">
              Paradise<br />
              Maui, Hawaii<br />
              March 2-7, 2025<br />
              Hyatt Regency Maui Resort &amp; Spa
            </p>
          </div>
          <div style={{ borderRight: 'none' }} className="cell" key="key2">
            <h4 className="cell-heading">Option II</h4>
            <img className="cell-image" src={rewardImages[1].node.file.url} alt={rewardImages[1].node.title} />
            <p className="trip-description">
              Jet Set<br />
              European Cruise<br />
              October 5-12, 2025<br />
              Royal Caribbean &quot;Allure of the Seas&quot; Ship<br />
              Stops in - Barcelona, Palma De Mallorca,<br />
              Florence, Rome, Naples
            </p>
          </div>
        </div>
      </RewardOptions>
      <h4 style={{ textTransform: 'uppercase', fontWeight: '600' }} className="center-align">How to Earn</h4>
      <CustomList>
        <CustomListItem>Total Points Needed: 45,000+</CustomListItem>
        <CustomListItem>Qualification Period: 7 months</CustomListItem>
        <CustomListItem># Months Required w/ Monthly Minimum: 5 months</CustomListItem>
        <CustomListItem>Monthly Minimum Requirement: 3000 points per month</CustomListItem>
      </CustomList>
      <PointSystem>
        <h4>Point System</h4>
        <p>
          1 Point = 1 CV<br />
          100 Points = Per Active Direct Per Month*<br />
          200 Points = Per New Artist Sign Up
        </p>
      </PointSystem>
    </>
  )
}

export default RewardsLevelFour
